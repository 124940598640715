'use client';

export const YandexMetrikaPixel = () => {
    // Target source to load pixel from.
    const pixelSource = `https://mc.yandex.ru/watch/100014098`;

    /* eslint-disable @next/next/no-img-element */
    return (
        <noscript>
            <div>
                <img src={pixelSource} style={{ position: 'absolute', left: '-9999px' }} alt='' />
            </div>
        </noscript>
    );
};
